import React, { useState, useEffect } from "react";
import socket from './Socket';

function GameListPanel(props) {
    const codePartie = props.codePartie
    const [joueurs, setJoueurs] = useState([])

    useEffect(() => {
        socket.on("listJoueurs", (data) => {
            let joueursTemp = []
            data.data.map(joueur =>(
                joueursTemp.push(joueur)
            ))
            setJoueurs(joueursTemp)
        })
    }, [])

    return (
        <div className="panel gamelistpanel">
            <h2 className="titres code-partie"># {codePartie}</h2>
            <>
                <hr className="ligne-hr"/>
            </>
            <div className="div-joueurs">
            {joueurs.map((joueur, index) => (
                <div key={joueur.pseudo} className="div-joueur">
                    <div>
                        <img src={process.env.PUBLIC_URL + "./avatars/" + joueur.avatar + ".svg"} alt={"avatar de " + joueur.pseudo}></img>
                        <span className="image-background"></span>
                    </div>
                    <div className="joueur-infos">
                        <h2 className="titres">{joueur.pseudo}</h2>
                        <h3 className="textes">{joueur.score}🏆</h3>
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
}
export default GameListPanel;