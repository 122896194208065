import React, { useState } from "react";
import SVG1 from "./icons/svg1.svg";
import SVG2 from "./icons/svg2.svg";
import SVG3 from "./icons/svg3.svg";
import useInterval from '@use-it/interval';


function LogPanel() {

  const [activeIndex, setActiveIndex] = useState(1);

  useInterval(() => {
    if(activeIndex === 3){
      setActiveIndex(1)
    }
    else{
      let newIndex = activeIndex + 1
      setActiveIndex(newIndex)
    }
  }, 4000);

  const showedTutoSVG = () =>{
    if(activeIndex === 1){
      return SVG1
    }
    if(activeIndex === 2){
      return SVG2
    }
    if(activeIndex === 3){
      return SVG3
    }
  }

  const showedTutoText = () =>{
    if(activeIndex === 1){
      return "Invite tes amis"
    }
    if(activeIndex === 2){
      return "Définissez vos règles"
    }
    if(activeIndex === 3){
      return "Défiez-vous !"
    }
  }

  

  return (
    <div className="panel tutopanel">
      <h2 className="titres">Comment jouer</h2>
      <div className="div-tuto">
        <div className="div-tuto-svg">
          <img alt={"image tuto " + showedTutoText()} src={showedTutoSVG()}/>
        </div>
        <h3 className="textes">{showedTutoText()}</h3>
      </div>
      <div className="div-tuto-click">
        <span className={activeIndex === 1 ? "span-click-active" : null} onClick={()=>setActiveIndex(1)}></span>
        <span className={activeIndex === 2 ? "span-click-active" : null} onClick={()=>setActiveIndex(2)}></span>
        <span className={activeIndex === 3 ? "span-click-active" : null} onClick={()=>setActiveIndex(3)}></span>
      </div>
    </div>
  );
}

export default LogPanel;