import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import GameListPanel from './GameListPanel';
import GameMenuPanel from './GameMenuPanel';
import GamePlayPanel from './GamePlayPanel';
import GameResultsPanel from './GameResultsPanel';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import socket from './Socket';


function Game(props) {
  let navigate = useHistory()
  const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";
  Axios.defaults.withCredentials = true;

  window.onpopstate = function(event) {
   navigate.go("/");
  };

  const codePartie = useLocation().state.codePartie
  const status = useLocation().state.status
  const [idUser, setIdUser] = useState(null)

  const [play, setPlay] = useState(false)

  const [results, setResults] = useState(false)

  const [officialTime, setOfficialTime] = useState("")
  const [officialNbrQuestion, setOfficialNbrQuestion] = useState("")

  const verifyParty = useCallback(() => {
    if (idUser !== undefined && idUser !== null && idUser !== "") {
      Axios.get(serverAdress + "verifyParty/" + codePartie).then((response) => {
        if (response.data.valid === true) {
          socket.emit("join", { idUser, codePartie }, () => {

          })
        }
        else if (response.data.valid === false) {
          navigate.push({ pathname: "/" })
        }
      })
    }
  }, [idUser, codePartie, navigate])

  useEffect(() => {
    Axios.get(serverAdress + "login").then((response) => {
      if (response.data.loggedIn === true) {
        setIdUser(response.data.user)
        verifyParty()
      }
      if (response.data.loggedIn === false) {
        props.setUser(null)
      }
    })
  }, [idUser, verifyParty])
  return (
    <>
      <GameListPanel codePartie={codePartie}/>
      {
        play ? (
          results ?(
            <GameResultsPanel codePartie={codePartie}/>
          ) : (
            <GamePlayPanel codePartie={codePartie} officialNbrQuestion={officialNbrQuestion} officialTime={officialTime} setResults={setResults}/>
          )
        ) : (
          <GameMenuPanel status={status} codePartie={codePartie} setPlay={setPlay} setOfficialNbrQuestion={setOfficialNbrQuestion} setOfficialTime={setOfficialTime}/>
        )
      }
    </>
  );
}

export default Game;