import React, { useState, useEffect } from "react";
import Axios from "axios";

function AccountPanel(props) {
    const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";
    Axios.defaults.withCredentials = true;

    const [id, setId] = useState("");
    const [pseudo, setPseudo] = useState("");
    const [avatar, setAvatar] = useState(0);
    const [score, setScore] = useState("");

    const changeAvatar = () =>{
        let randomNumber = Math.floor(Math.random() * (8 - 0 + 0) + 0)

        Axios.post(serverAdress + "changeAvatar",
        {id: id,
        avatar: randomNumber}).then((response)=>{
            if(response.data.loggedIn === false){
                props.setUser(null)
            }
            if(response.data.error){
            }
            else{
                setAvatar(randomNumber)
            }
        })
    }

    useEffect(() => {
        Axios.get(serverAdress + "infoUser").then((response) => {
            if (response.data.loggedIn === true) {
                setId(response.data.id)
                setPseudo(response.data.pseudo)
                setAvatar(response.data.avatar)
                setScore(response.data.score)
            }
            else if(response.data.loggedIn === false){
                props.setUser(null)
            }
        })
    }, [serverAdress])

    return (
        <div className="panel accountpanel">
            <div>
                <img alt={"avatar de " + pseudo}src={process.env.PUBLIC_URL + "./avatars/" + avatar + ".svg"}></img>
                <span className="image-background"></span>
                <span className="change-image" onClick={changeAvatar}></span>
            </div>
            <h2 className="titres">{pseudo}</h2>
            <h3 className="textes">{score} 🏆</h3>
        </div>
    );
}

export default AccountPanel;