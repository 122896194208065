import React, { useState, useEffect } from "react";
import socket from './Socket';

function GameResultsPanel(props) {
  const [winner, setWinner] = useState("")
  const [badAnswers, setBadAnswers] = useState("")
  const [scoreTotal, setScoreTotal] = useState("")

  useEffect(()=>{
    socket.on("results", (data) => {
      setWinner(data.winner)
      setBadAnswers(data.badAnswers)
      setScoreTotal(data.scoreTotal)
    })
  })

  return (
    <div className="panel gamemenupanel">
      <h2 className="titres">Les résultats !</h2>
      <div className="resultats">
        <div>
          <h3 className="textes">{scoreTotal}</h3>
          <h3 className="textes">C'est le nombre de bonnes réponses !</h3>
        </div>
        <div>
          <h3 className="textes">{badAnswers}</h3>
          <h3 className="textes">C'est le nombre de mauvaises réponses !</h3>
        </div>
        <div>
          <h3 className="titres">{winner} est notre grand gagnant !</h3>
        </div>
      </div>
      <a className="textes resultbutton buttonform" href="/">Revenir à l'accueil <i className="button-logo"></i></a>
    </div>
  );
}

export default GameResultsPanel;