import React, { useState, useEffect } from "react";
import socket from './Socket';

function GamePlayPanel(props) {
  const setResults = props.setResults

  const [temps, setTemps] = useState(props.officialTime)

  let timePassed = 0;
  let timerInterval = null;
  let timeLeft = temps
  let fullDashArray = 283

  const [allowAnswer, setAllowAnswer] = useState(true)

  const [question, setQuestion] = useState("question")
  const [reponse1, setReponse1] = useState("reponse")
  const [reponse2, setReponse2] = useState("reponse")
  const [reponse3, setReponse3] = useState("reponse")
  const [reponse4, setReponse4] = useState("reponse")

  const onTimesUp = () => {
    clearInterval(timerInterval);
  }

  useEffect(() => {
    socket.on("changeQuestion", (data) => {
      timePassed = 0;
      timerInterval = null;
      timeLeft = temps
      timer()
      
      setQuestion(data.question)
      setReponse1(data.reponse1)
      setReponse2(data.reponse2)
      setReponse3(data.reponse3)
      setReponse4(data.reponse4)

      changeQuestionAnimation()

      setAllowAnswer(true)
    })

    socket.on("correctReponse", (data) => {
      setAllowAnswer(false)
      correctReponseAnimation(data.answer)
    })

    socket.on("resultats", () => {
      setResults(true)
    })
  }, [])

  const changeQuestionAnimation = () =>{
    let reponse1 = document.querySelector("#reponse1")
    let reponse2 = document.querySelector("#reponse2")
    let reponse3 = document.querySelector("#reponse3")
    let reponse4 = document.querySelector("#reponse4")

    reponse1.classList.remove("notanswer")
    reponse2.classList.remove("notanswer")
    reponse3.classList.remove("notanswer")
    reponse4.classList.remove("notanswer")

    reponse1.classList.remove("correctanswer")
    reponse2.classList.remove("correctanswer")
    reponse3.classList.remove("correctanswer")
    reponse4.classList.remove("correctanswer")

    reponse1.classList.remove("falseanswer")
    reponse2.classList.remove("falseanswer")
    reponse3.classList.remove("falseanswer")
    reponse4.classList.remove("falseanswer")
  }

  const correctReponseAnimation = (answer) =>{
    let reponse1 = document.querySelector("#reponse1")
    let reponse2 = document.querySelector("#reponse2")
    let reponse3 = document.querySelector("#reponse3")
    let reponse4 = document.querySelector("#reponse4")

    reponse1.classList.add("falseanswer")
    reponse2.classList.add("falseanswer")
    reponse3.classList.add("falseanswer")
    reponse4.classList.add("falseanswer")

    document.querySelector("#reponse" + answer).classList.remove("falseanswer")
    document.querySelector("#reponse" + answer).classList.add("correctanswer")
  }

  const timer = () => {
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1
      timeLeft = temps - timePassed
      document.querySelector("#timer").innerHTML = timeLeft;
      setCircleDasharray()
      if (timeLeft === 0) {
        onTimesUp()
      }
    }, 1000)
  }

  const calculateTimeFraction = () => {
    return timeLeft / temps;
  }

  const setCircleDasharray = () => {
    const circleDasharray = `${(
      calculateTimeFraction() * fullDashArray
    ).toFixed(0)} 283`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dasharray", circleDasharray);
  }

  const selectAnswer = (e) => {
    if (allowAnswer) {
      let reponse = parseInt(e.target.value)

      socket.emit("answer", { reponse }, () => {

      })

      let reponse1 = document.querySelector("#reponse1")
      let reponse2 = document.querySelector("#reponse2")
      let reponse3 = document.querySelector("#reponse3")
      let reponse4 = document.querySelector("#reponse4")

      if (reponse === 1) {
        reponse1.classList.remove("notanswer")
        reponse2.classList.add("notanswer")
        reponse3.classList.add("notanswer")
        reponse4.classList.add("notanswer")
      }
      if (reponse === 2) {
        reponse1.classList.add("notanswer")
        reponse2.classList.remove("notanswer")
        reponse3.classList.add("notanswer")
        reponse4.classList.add("notanswer")
      }
      if (reponse === 3) {
        reponse1.classList.add("notanswer")
        reponse2.classList.add("notanswer")
        reponse3.classList.remove("notanswer")
        reponse4.classList.add("notanswer")
      }
      if (reponse === 4) {
        reponse1.classList.add("notanswer")
        reponse2.classList.add("notanswer")
        reponse3.classList.add("notanswer")
        reponse4.classList.remove("notanswer")
      }
    }
  }

  return (
    <div className="panel gamemenupanel">
      <div className="timer">
        <div>
          <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="base-timer__circle">
              <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45" />
              <path id="base-timer-path-remaining" strokeDasharray="283" className="base-timer__path-remaining" d="M 50, 50m -45, 0a 45,45 0 1,0 90,0a 45,45 0 1,0 -90,0"></path>
            </g>
          </svg>
          <h3 id="timer" className="textes">{temps}</h3>
        </div>
      </div>
      <div className="div_question">
        <button className="textes">{question}</button>
        <div className="div_reponses">
          <div>
            <button id="reponse1" value="1" className="textes" onClick={(e) => { selectAnswer(e) }}>{reponse1}</button>
          </div>
          <div>
            <button id="reponse2" value="2" className="textes" onClick={(e) => { selectAnswer(e) }}>{reponse2}</button>
          </div>
          <div>
            <button id="reponse3" value="3" className="textes" onClick={(e) => { selectAnswer(e) }}>{reponse3}</button>
          </div>
          <div>
            <button id="reponse4" value="4" className="textes" onClick={(e) => { selectAnswer(e) }}>{reponse4}</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamePlayPanel;