import LogPanel from './LogPanel';
import TutoPanel from './TutoPanel';
import AccountPanel from './AccountPanel';
import PlayPanel from './PlayPanel';
import Game from './Game';
import About from './About';
import Conditions from './Conditions';
import Admin from './Admin';
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";

  const [user, setUser] = useState("");

  Axios.defaults.withCredentials = true;

  const checkLogin = () =>{
    Axios.get(serverAdress + "login").then((response)=>{
      if(response.data.loggedIn === true){
        setUser(response.data.user)
      }
  })
  }

  useEffect(()=>{
    checkLogin()
  }, [])

  const showModal = () =>{
    document.getElementById("footer").classList.toggle("active")
  }

  return (
    <div className="background">
      <div className="menu-div">
        <h1 className="titres maintitre">QuizParty</h1>
        <h2 className="textes">Montre que tu es le plus malin</h2>        
        <span className="modal-about" onClick={()=>{showModal()}}>i</span>  
        <div className="panels">
        {<BrowserRouter>
          <Switch>
            <Route exact path="/">
              <>
                {!user ? (
                    <>
                      <LogPanel checkLogin={checkLogin}/>
                      <TutoPanel/>
                    </>
                  ) : (
                    <>
                      <AccountPanel setUser={setUser}/>
                      <PlayPanel setUser={setUser}/>
                    </>
                )}
              </>
            </Route>
            <Route exact path="/game">
              <Game setUser={setUser}/>
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/conditions">
              <Conditions />
            </Route>
            <Route exact path="/admin">
              <Admin />
            </Route>
          </Switch>
        </BrowserRouter>}
        </div>
        <footer id="footer" className="footer">
          <span className="modal-about" onClick={()=>{showModal()}}>x</span>  
          <a href="/conditions"><h3>Conditions d'utilisation</h3></a>
          <a href="/about"><h3>En savoir plus</h3></a> 
        </footer>
      </div>
    </div>
  );
}

export default App;
