import React, { useState } from "react";
import Login from './Login';
import Register from './Register';

function LogPanel(props) {
    const [activeIndex, setActiveIndex] = useState(1);
    
    return (
      <div className="panel logpanel">
        <div className="log-div-choose">
            <h2 className={activeIndex === 2 ? "log-titres-active titres log-titres log-titres-con" : "titres log-titres log-titres-con"} onClick={()=>setActiveIndex(1)}>Se connecter</h2>
            <h2 className={activeIndex === 1 ? "log-titres-active titres log-titres log-titres-insc" : "titres log-titres log-titres-insc"}  onClick={()=>setActiveIndex(2)}>S'inscrire</h2>
        </div>
        <div className="log-div">
            {activeIndex === 1 ? (
                <Login checkLogin={props.checkLogin}/>
            ) : (
                <Register changeIndex={setActiveIndex}/>
            )}
        </div>
      </div>
    );
  }
  
  export default LogPanel;