
function About() {
    return (
        <>
        <a className="textes buttonform buttoninfos" href="/">Accueil</a>
        <div className="panel aboutpanel">
            <h2 className="titres">A propos</h2>
            <p className="textes">QuizParty est un jeu créé par Maxence Bureau en 2021.</p>
            <p className="textes">Vous pouvez retrouvez son portfolio et plusieurs de ses réalisations sur le site <a href="https://maxence-bureau.fr">maxence-bureau.fr</a></p>
            <p className="textes">Pour toutes questions relatives à QuizParty n'hésitez pas à le contacter à l'adresse maxencebureau0[at]gmail.com</p>
        </div>
        </>
    );
}
export default About;