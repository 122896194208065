import React, { useState } from "react";
import Axios from "axios"

function Login(props) {
  const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";

  const [pseudoUserLogin, setPseudoUserLogin] = useState("");
  const [mdpUserLogin, setMdpLogin] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const login = ()=>{
    Axios.post(serverAdress + "login",
    {username: pseudoUserLogin,
    pwd: mdpUserLogin}).then((response)=>{
        if(response.data.message){
            setLoginStatus(response.data.message)
        }
        else{
          props.checkLogin()
        }
    })
}

  return (
    <form className="form-login">
      {loginStatus}
      <div>
        <label className="textes labelform" htmlFor="pseudoUser">Pseudo</label>
        <input className="textes inputform" placeholder="Pseudo" type="text" id="pseudoUser" required onChange={(e) => { setPseudoUserLogin(e.target.value) }}></input>
      </div>
      <div>
        <label className="textes labelform" htmlFor="mdpUser">Mot de passe</label>
        <input className="textes inputform" placeholder="Mot de passe" type="password" id="mdpUser" required onChange={(e) => { setMdpLogin(e.target.value) }}></input>
      </div>
      <button className="textes buttonform" type="button" onClick={login}>Connexion <i className="button-logo"></i></button>
    </form>
  );
}

export default Login;
