import React, { useState, useEffect } from "react";
import timer from "./icons/timer.svg";
import questions from "./icons/questions.svg";
import socket from './Socket';

function GameMenuPanel(props) {
  const setOfficialNbrQuestion = props.setOfficialNbrQuestion
  const setOfficialTime = props.setOfficialTime
  const play = props.setPlay
  const status = props.status
  const codePartie = props.codePartie
  const [temps, setTemps] = useState(20)
  const [nbrQuestions, setNbrQuestions] = useState(15)

  const changeVariables = () => {
    let tmpTemps = parseInt(document.querySelector("#temps").value)
    let tmpNbrQuestions = parseInt(document.querySelector("#nbrQuestions").value)

    setTemps(tmpTemps)
    setNbrQuestions(tmpNbrQuestions)

    emitVariables(tmpTemps, tmpNbrQuestions)
  }

  const emitVariables = (tmpTemps, tmpNbrQuestions) => {
    let temps = tmpTemps
    let nbrQuestions = tmpNbrQuestions
    socket.emit("changeReglages", { temps, nbrQuestions, codePartie }, () => {

    })
  }

  const startGame = () => {
    socket.emit("startGame", { codePartie }, () => {

    })
  }

  useEffect(() => {
    if (status === "player") {
      socket.on("reglages", (data) => {
        setTemps(data.temps)
        setNbrQuestions(data.nbrQuestions)
      })
    }
    socket.on("launchGame", (data) => {
      setOfficialTime(data.temps)
      setOfficialNbrQuestion(data.nbrQuestions)
      play(true)
    })
  })

  return (
    <div className="panel gamemenupanel">
      <h2 className="titres">Réglages</h2>
      <div className="reglages">
        <div className="reglage">
          <img alt="timer" src={timer}></img>
          <div>
            <h3 className="titres">Temps</h3>
            <p className="textes">Modifie le temps que les joueurs ont pour répondre à la question</p>
          </div>
          <form>
            {
              status === "host" ? (
                <input id="temps" className="textes inputform" type="number" min="5" max="60" defaultValue="10" onChange={() => { changeVariables() }}></input>
              ) : (
                <input className="textes inputform" type="number" min="5" max="60" value={temps} disabled></input>
              )
            }
          </form>
        </div>
        <div className="reglage">
          <img alt="questions" src={questions}></img>
          <div>
            <h3 className="titres">Questions</h3>
            <p className="textes">Modifie le nombre de questions qui seront posées durant la partie</p>
          </div>
          <form>
            {
              status === "host" ? (
                <input id="nbrQuestions" className="textes inputform" type="number" min="5" max="50" defaultValue="15" onChange={() => { changeVariables() }}></input>
              ) : (
                <input className="textes inputform" type="number" min="5" max="50" value={nbrQuestions} disabled></input>
              )
            }
          </form>
        </div>
      </div>
      {
        status === "host" ? (
          <button className="textes buttonform button-reglage" type="button" onClick={() => { startGame() }}>Commencer <i className="button-logo"></i></button>
        ) : (
          <button className="textes buttonform button-reglage" type="button" disabled>Commencer <i className="button-logo"></i></button>
        )
      }
    </div>
  );
}

export default GameMenuPanel;