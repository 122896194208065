import React, { useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router-dom";

function PlayPanel(props) {
    let navigate = useHistory()
    const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";
    Axios.defaults.withCredentials = true;

    const [codeParty, setCodeParty] = useState("");

    const createParty = () => {
        Axios.post(serverAdress + "createParty").then((response)=>{
            if(response.data.success === true){
                navigate.push({pathname:"/game", state: {codePartie: response.data.codePartie, status: "host"}})
            }
            if(response.data.loggedIn === false){
                props.setUser(null)
            }
        })
    }

    const joinParty = () => {
        if(!codeParty){
            
        }
        else{
            Axios.post(serverAdress + "joinParty",
            {
                codeParty: parseInt(codeParty)
            }).then((response) =>{
                if(response.data.success === true){
                    navigate.push({pathname:"/game", state: {codePartie: response.data.codePartie, status: "player"}})
                }
                if(response.data.loggedIn === false){
                    props.setUser(null)
                }
            })
        }
    }

    return (
        <div className="panel playpanel">
            <h2 className="titres">Jouer</h2>
            <div>
                <form className="form-create">
                    <button className="textes buttonform" type="button" onClick={createParty}>Créer ma partie <i className="button-logo"></i></button>
                </form>
                <form className="form-join">
                    <div>
                        <label className="textes labelform" htmlFor="codeParty">Code de la partie</label>
                        <input className="textes inputform" placeholder="Code Partie" id="codeParty" onChange={(e) => { setCodeParty(e.target.value) }}></input>
                    </div>
                    <button className="textes buttonform" type="button" onClick={joinParty}>Rejoindre <i className="button-logo"></i></button>
                </form>
            </div>
        </div>
    );
}

export default PlayPanel;