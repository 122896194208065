import React, { useState } from "react";
import Axios from "axios"
import { useHistory } from "react-router-dom";

function Admin() {
    let navigate = useHistory()
    const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";
    Axios.defaults.withCredentials = true;

    const [login, setLogin] = useState("");
    const [mdp, setMdp] = useState("");

    const [connected, setConnected] = useState(false)

    const [question, setQuestion] = useState("");

    const [reponse1, setReponse1] = useState("");
    const [reponse2, setReponse2] = useState("");
    const [reponse3, setReponse3] = useState("");
    const [reponse4, setReponse4] = useState("");

    const [correct, setCorrect] = useState("");

    const connect = () =>{
        Axios.post(serverAdress + "adminLogin",
        {username: login,
        pwd: mdp}).then((response)=>{
            if(response.data.message){
                console.log("ERROR")
            }
            else{
                setConnected(true)
                resetForm()
            }
        })
    }

    const resetForm = () =>{
        document.querySelector("#question").value=""
        document.querySelector("#reponse1").value=""
        document.querySelector("#reponse2").value=""
        document.querySelector("#reponse3").value=""
        document.querySelector("#reponse4").value=""
        document.querySelector("#correct").value=""
    }

    const addQuestion = () => {
        Axios.post(serverAdress + "adminAdd",
        {question: question,
        reponse1: reponse1,
        reponse2: reponse2,
        reponse3: reponse3,
        reponse4: reponse4,
        correct: correct}).then((response)=>{
            if(response.data.message){
                console.log("ERROR")
            }
            else{
                setQuestion("")
                setReponse1("")
                setReponse2("")
                setReponse3("")
                setReponse4("")
                setCorrect("")
                
                resetForm()
            }
        })
    }

    return (
        <>
            <a className="textes buttonform buttoninfos" href="/">Accueil</a>
            <div className="panel aboutpanel">
                <h2 className="titres">Admin</h2>
                {!connected ? (
                    <form>
                        <div>
                            <label className="textes labelform" htmlFor="login">Login</label>
                            <input className="textes inputform" placeholder="Login" id="login" onChange={(e) => { setLogin(e.target.value) }}></input>
                        </div>
                        <div>
                            <label className="textes labelform" htmlFor="mdp">Mot de passe</label>
                            <input type="password" className="textes inputform" placeholder="Mot de passe" id="mdp" onChange={(e) => { setMdp(e.target.value) }}></input>
                        </div>
                        <button className="textes buttonform" type="button" onClick={connect}>Rejoindre <i className="button-logo"></i></button>
                    </form>

                ) :
                    (
                        <form>
                            <div>
                                <label className="textes labelform" htmlFor="question">Question</label>
                                <input className="textes inputform" placeholder="Question" id="question" onChange={(e) => { setQuestion(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="textes labelform" htmlFor="reponse1">Reponse1</label>
                                <input className="textes inputform" placeholder="Question" id="reponse1" onChange={(e) => { setReponse1(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="textes labelform" htmlFor="reponse2">Reponse2</label>
                                <input className="textes inputform" placeholder="Question" id="reponse2" onChange={(e) => { setReponse2(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="textes labelform" htmlFor="reponse3">Reponse3</label>
                                <input className="textes inputform" placeholder="Question" id="reponse3" onChange={(e) => { setReponse3(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="textes labelform" htmlFor="reponse4">Reponse4</label>
                                <input className="textes inputform" placeholder="Question" id="reponse4" onChange={(e) => { setReponse4(e.target.value) }}></input>
                            </div>
                            <div>
                                <label className="textes labelform" htmlFor="correct">Réponse Correcte</label>
                                <input className="textes inputform" placeholder="Réponse Correcte" id="correct" onChange={(e) => { setCorrect(e.target.value) }}></input>
                            </div>
                            <button className="textes buttonform" type="button" onClick={addQuestion}>Ajouter <i className="button-logo"></i></button>
                        </form>
                    )}
            </div>
        </>
    );
}
export default Admin;