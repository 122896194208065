import React, { useState } from "react";
import Axios from "axios"

function Register(props) {
  const serverAdress = "https://serveur.quizparty.maxence-bureau.fr/";

  const [pseudoUserLogin, setPseudoUserLogin] = useState("");
  const [mailUserLogin, setMailUserLogin] = useState("");
  const [mdpUserLogin, setMdpLogin] = useState("");
  const [mdpVerify, setMdpVerify] = useState("");
  const [registerStatus, setRegisterStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const register = () => {
    if (pseudoUserLogin.length > 10) {
      setRegisterStatus("Pseudo trop long (moins de 10 caractères)")
    }
    else {
      if (pseudoUserLogin && mailUserLogin && mdpUserLogin && mdpVerify) {
        if (mdpUserLogin === mdpVerify) {
          Axios.post(serverAdress + "register",
            {
              username: pseudoUserLogin,
              mail: mailUserLogin,
              pwd: mdpUserLogin
            }).then((response) => {
              if (response.data.message) {
                setRegisterStatus(response.data.message)
              }
              else {
                props.changeIndex(1)
              }
            })
        }
        else {
          setRegisterStatus("Les mots de passe ne correspondent pas")
        }
      }
      else {
        setRegisterStatus("Les champs ne sont pas remplis")
      }
    }
  }

  return (
    <form className="register">
      <h3>{registerStatus}</h3>
      <div className="form-register">
        <div className="form-login-left">
          <div>
            <label className="textes labelform" htmlFor="pseudoUser">Pseudo</label>
            <input className="textes inputform" placeholder="Pseudo" type="text" id="pseudoUser" required onChange={(e) => { setPseudoUserLogin(e.target.value) }}></input>
          </div>
          <div>
            <label className="textes labelform" htmlFor="mailUser">Mail</label>
            <input className="textes inputform" placeholder="Mail" type="email" id="mailUser" required onChange={(e) => { setMailUserLogin(e.target.value) }}></input>
          </div>
        </div>
        <div className="form-login-right">
          <div>
            <label className="textes labelform" htmlFor="mdpUser">Mot de passe</label>
            <input className="textes inputform" placeholder="Mot de passe" type="password" id="mdpUser" required onChange={(e) => { setMdpLogin(e.target.value) }}></input>
          </div>
          <div>
            <label className="textes labelform" htmlFor="mdpVerifyUser">Vérifiez mot de passe</label>
            <input className="textes inputform" placeholder="Vérifiez Mot de passe" type="password" id="mdpVerifyUser" required onChange={(e) => { setMdpVerify(e.target.value) }}></input>
          </div>
        </div>
      </div>
      <button className="textes buttonform" type="button" onClick={register}>Connexion <i className="button-logo"></i></button>
    </form>
  );
}

export default Register;
